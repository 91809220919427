import React from "react"
import { Card } from "../Card"
import { enumFrom } from "../ImageWithLinkOrNot"
import { Description, Wrapper } from "./cards.style"
import { v4 as uuidv4 } from "uuid"
import { buildHtmlFromString } from "../../../lib/buildHtmlFromString"
import { useDetectDevice } from "../../../lib/useDetectDevice"
const edjsHTML = require("editorjs-html")

interface Props {
  data: Record<string, any>
  lineNumberOfCards?: number
  from?: enumFrom
  objectFit?: string
  className?: string
}

export function CardsView({
  data,
  lineNumberOfCards = 3,
  from = enumFrom.STRAPI,
  objectFit = "contain",
  className = ""
}: Props) {


  const isMobile = useDetectDevice()
  const classNames = className + " " + (isMobile && "mobile")

  // const image = data.Image?.backgroundImage?.url
  return (
    <Wrapper number={lineNumberOfCards} className={classNames}>
      {data &&
        data.map((item: any) => {
          const image = { ...item.Image, ...item.Image?.url }
          return (
            <Card image={image} text={""} link={item.Link} key={uuidv4()}>
              <ImageUnder data={item} />
            </Card>
          )
        }


        )}
    </Wrapper>
  )
}

const ImageUnder = ({ data }) => {

  const item = {
    Image: {
      name: data.Image?.name,
      url: data.Image?.backgroundImage?.url,
      alt: data.Image?.alt,
    },
    Text: data?.Text,
  }

  let htmlFormatted = ""
  if (item.Text) {
    const edjsParser = edjsHTML()
    const html = edjsParser.parse(JSON.parse(item.Text)).join("")
    htmlFormatted =
      html.length > 200 ? html.substring(0, 200 - 1) + "&hellip;" : html
  }

  return (
    <Description>
      {data && (
        buildHtmlFromString(htmlFormatted)
        // </Text>
      )}
    </Description>
  )
}

export function SampleNextArrow(props: { className?: any; onClick?: any }) {
  const { className, onClick } = props
  return <div className={className} onClick={onClick} />
}

export function SamplePrevArrow(props: { className?: any; onClick?: any }) {
  const { className, onClick } = props
  return <div className={className} onClick={onClick} />
}

