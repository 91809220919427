import React from "react"
import { useDetectDevice } from "../../../lib/useDetectDevice"
import { DisplayProduct } from "../../specific/TemplateProducts/ProductsPagined/components/DisplayProduct"
import { Spinner } from "../Spinner"
import { Title } from "./shortListProduct.style"

export const ShortListProductView = ({
  products = [],
  loading,
  title,
  className,
}: {
  products: any[]
  loading: boolean
  title: string
  className: string
}) => {

  const isMobile = useDetectDevice()
  const classNames = (className && className) + " " + (isMobile && "mobile")


  if (loading) return <Spinner />

  return (
    <>
      <Title label={title} className={classNames}></Title>
      <DisplayProduct products={products} className={classNames}></DisplayProduct>
    </>
  )
}
