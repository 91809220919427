


import styled from "styled-components"

export const Wrapper = styled.div`
display:flex;
flex-direction: column;
padding:1.5em;

h1 {
    text-align: center;
    margin: 0 0 15px 0;
    ${props => props.theme.typography.h1}
  }

`
