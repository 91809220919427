import React from 'react';
import { ColoredLine } from '../ColorLine';
import { Wrapper } from './title.style';

interface Props {

    label?: string
    children?: React.ReactChild
    className: string
}

export const TitleView = ({ label, children, className }: Props) => {
    if (!(label || children)) return <></>

    return (
        <Wrapper className={className}>
            {label && (<h1>{label}</h1>)}
            {children}
            <ColoredLine />
        </Wrapper>
    )
}




