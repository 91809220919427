
import * as React from 'react'
import { graphql } from 'gatsby'
import { extractFilterProductAttribute, extractFilterVariantAttribute } from '../../config/helper/reducers'
import { PageUnivers } from '../components/specific/PageUnivers'

type Category = { slug: string, name: string, backgroundImage: { url: string }, description: string }
type Faq = { question: { data: { childMarkdownRemark: { html: string } } }, reponse: { data: { childMarkdownRemark: { html: string } } } }
type Products = { node: any }
type Lien = { name: string, slug: string }

export const query = graphql`query univerQuery($categoryId: ID, $categoryIdString: String) {
  allStrapiCategorieContent(
    filter: {categorie: {saleor_id: {eq: $categoryIdString}}}
  ) {
    edges {
      node {
        courteListeProduit {
          titre
          collections_saleor {
            saleor_id
          }
        }
        description {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        contenuSEO {
          data {
            childMarkdownRemark {
              html
            }
          }
        }
        titre_lien_bas_page
        contenu_liens_haut_de_page {
          name
          saleor_id
          slug
        }
        contenu_liens_bas_de_page {
          name
          saleor_id
          slug
        }
        faq {
          question {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
          reponse {
            data {
              childMarkdownRemark {
                html
              }
            }
          }
        }
        id
      }
    }
  }
  saleor {
    category(id: $categoryId) {
      name
      slug
      level
      id
      seoTitle
      seoDescription
      ...categoryChildFrag

    }
    products(
      first: 60
      filter: {isPublished: true, categories: [$categoryId]}
      sortBy: {direction: ASC, field: RATING}
      channel: "default-channel"
    ) {
      totalCount
      pageInfo {
        hasNextPage
        endCursor
      }
      edges {
        node {
          ...fragProduct
          thumbnail(size: 512) {
            url
            alt
          }
        }
      }
    }
  }
}

fragment fragCategoryFromProduct on SALEOR_Category {
  id
  name
  slug
  level
  parent {
    id
    name
    slug
    level
    parent {
      id
      name
      slug
      level
    }
  }
}

fragment fragProduct on SALEOR_Product {
  id
  name
  slug
  variants {
    metadata {
      key
      value
    }
    attributes {
      attribute {
        name
        slug
      }
      values {
        name
        slug
      }
    }
  }
  category {
    ...fragCategoryFromProduct
  }
  metadata {
    key
    value
  }
  seoTitle
  seoDescription
  isAvailable
  availableForPurchase
  isAvailableForPurchase
  pricing {
    onSale
    discount {
      net {
        amount
        currency
      }
    }
    priceRangeUndiscounted {
      start {
        gross {
          amount
        }
      }
    }
    priceRange {
      start {
        gross {
          amount
          currency
        }
      }
    }
  }
  attributes {
    values {
      name
      slug
    }
    values {
      name
      slug
    }
    attribute {
      name
      slug
    }
  }
}

fragment categoryChildFrag on SALEOR_Category {
  parent {
    name
    slug
    parent {
      slug
      name
    }
  }
  children(first: 100) {
    totalCount
    edges {
      node {
        level
        slug
        name
        description
        backgroundImage {
          url
        }
        children(first: 100) {
          totalCount

        }
      }
    }
  }
}

`
function extractData(data: any) {

  let result = {}
  try {
    result = data.allStrapiCategorieContent.edges.map(
      ({ node }: any) => {
        return {
          description: node.description?.data.childMarkdownRemark.html,
          contenuSEO:
            node.contenuSEO?.data.childMarkdownRemark.html,
          courteListeProduit: node.courteListeProduit,
          liens_haut_page: node.contenu_liens_haut_de_page?.map(({ name, slug }: Lien) => ({ name, slug })),
          liens_bas_page: node.contenu_liens_bas_de_page?.map(({ name, slug }: Lien) => ({ name, slug })),
          faq: node.faq?.map(({ question, reponse }: Faq) => ({ "question": question?.data.childMarkdownRemark.html, "reponse": reponse?.data.childMarkdownRemark.html })),
          titre_lien_bas_page: node.titre_lien_bas_page
        }
      }
    )[0]

  } catch (error) {
    console.warn("some data from CollectionContent are missing", error)
  }

  const { edges, pageInfo, totalCount } = data.saleor.products
  const products = edges?.map(({ node }: Products) => node)
  result = { ...result, ...pageInfo, ...totalCount, products }

  return result as {
    description: string,
    contenuSEO: string,
    titre_lien_bas_page: string,
    liens_haut_page: any,
    liens_bas_page: any,
    faq: { question: string, reponse: string },

  }


}

const Univers = ({ pageContext, data }: { pageContext: any, data: any }): JSX.Element => {

  // calcul de la liste d'id de productType a utiliser pour chercher les filtres
  let attributesValableFromProduct =
    data.saleor.products.edges.reduce(
      extractFilterProductAttribute,
      {}
    )
  let attributesValableFromVariant =
    data.saleor.products.edges.reduce(
      extractFilterVariantAttribute,
      {}
    )

  pageContext = {
    ...pageContext,
    ...extractData(data),
    ... {
      filters: {
        ...attributesValableFromProduct,
        ...attributesValableFromVariant,
      }
    }
  }


  const listOfSubCategories: Category[] = data.saleor.category.children.edges.map(({ node }: any) => ({ ...node }))


  pageContext.data = { ...pageContext.data, listOfSubCategories, category: data.saleor.category, productName: data.saleor.category.name }

  return <PageUnivers pageContext={pageContext} />


}


export default Univers
