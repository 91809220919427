import React from "react"

import { Wrapper, Text, Image } from "./Card.style"
import { ImageWithLinkOrNot } from "./../ImageWithLinkOrNot"
import { Link } from "./../Link"
import { useDetectDevice } from "../../../lib/useDetectDevice"

interface Props {
  className?: string
  image: Record<string, any>
  link?: string
  text: string
  children?: React.ReactChildren
}

export function CardView({ className, image, link, text, children }: Props) {

  const isMobile = useDetectDevice()
  const classNames = className + " " + (isMobile && "mobile")
  return (
    <Wrapper className={classNames}>
      <Link link={link}>
        <Image data={image} />
      </Link>
      <Text>{text}</Text>
      {children}
    </Wrapper>
  )
}
