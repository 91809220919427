import React, { memo } from "react"
import { useDetectDevice } from "../../../../../lib/useDetectDevice"
import { Spinner } from "../../../../common/Spinner"
import { List, ItemWrapper, Padding } from "../ProductsPagined.style"
import { v4 as uuidv4 } from "uuid"
import { ProductListItem } from "../../../../common/ProductListItem"

export interface Props {
  products: any
  className: string
}

export const DisplayProduct: React.FC<Props> = memo(props => {
  const isMobile = useDetectDevice()
  const classNames = (props.className && props.className) + " " + (isMobile && "mobile")

  if (!props.products) return <Spinner />

  return (
    <List className={classNames}>
      {props.products.map((product, index) => {
        return (
          <Padding className={classNames} key={uuidv4()}>
            <ItemWrapper className={classNames}>
              <ProductListItem {...product} />
            </ItemWrapper>
          </Padding>
        )
      })}
    </List>
  )
})
