import styled from "styled-components"
import { Image } from "./../Card/Card.style"

export const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  gap: 6.5em;
  margin: 0 auto;
  &.mobile {
    grid-template-columns: 1fr;
  }
`

export const Description = styled.div`
  /* ${props => props.theme.typography.p} */
  margin-bottom: 15px;
  h1 {
    ${props => props.theme.typography.h1}
    font-weight: 600;
    font-size: 22px;
    line-height: 22px;
    text-transform: uppercase;
    margin: 0 0 15px 0;
  }
  p {
    margin: 0;
    ${props => props.theme.typography.p}
    text-align: left;
    font-size: 15px;
  }
  a {
    color: black;
  }
`
