import styled from "styled-components"
import { ImageWithLinkOrNot } from "../ImageWithLinkOrNot"
import { CommonParagraph } from "../Texts"

export const Image = styled(ImageWithLinkOrNot)`
  width: min(25vw, 380px);
  height: min(25vw, 380px);
  object-fit: cover;
`

export const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-content: normal;

  &.mobile ${Image} {
    max-width: min(95%, 380px);
  }
`

export const Text = styled(CommonParagraph)`
  font-size: smaller;
  text-transform: uppercase;
  background-color: red;
`
