import styled from "styled-components"
import { Title as TitleBase } from "../Title"

export const Title = styled(TitleBase)`
  h1{
    margin: 0;
  }
  &.mobile{
    h1{
      padding-top: 2em;
      font-size: 30px;
    }
  }
`