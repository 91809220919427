// reduceur pour recuperer les fiPas de produit disponibleltre contenue dans le type de product
export const extractFilterProductAttribute = (accumulator, currentValue) => {
  const { node } = currentValue
  node.attributes?.forEach(item => {
    const filterName = item.attribute.name
    const filterSlug = item.attribute.slug
    if (!accumulator[filterName]) {
      accumulator[filterName] = []
    }
    item.values.forEach(item => {
      // si la valeur de l'option du filtre n'existe pas deja on l'ajoute a a liste des valeur possible du filtre
      if (!accumulator[filterName].some(x => x.name == item.name)) {
        accumulator[filterName].push({
          name: item.name,
          filterSlug: filterSlug,
          slug: item.slug,
        })
      }
    })
  })

  return accumulator
}

// reduceur pour recuperer les filtre contenue dans le  variant
export const extractFilterVariantAttribute = (accumulator, currentValue) => {
  const { node } = currentValue

  let attributes = []

  node.variants?.forEach(variant => {
    attributes = attributes.concat(variant.attributes)
  })
  attributes.forEach(item => {
    const filterName = item.attribute.name
    const filterSlug = item.attribute.slug
    if (!accumulator[filterName]) {
      accumulator[filterName] = []
    }
    item.values.forEach(item => {
      // si la valeur de l'option du filtre n'existe pas deja on l'ajoute a a liste des valeur possible du filtre
      if (!accumulator[filterName].some(x => x.name == item.name)) {
        accumulator[filterName].push({
          name: item.name,
          filterSlug: filterSlug,
          slug: item.slug,
        })
      }
    })
  })

  return accumulator
}
