import InfiniteScroll from "react-infinite-scroll-component"
import styled, { keyframes } from "styled-components"
import { Button } from "../../../common/Button"
import { WrapperMainComponent } from "../../../common/Layout"
import { CommonParagraph } from "../../../common/Texts"

export const ItemWrapper = styled.div`
  padding: 5px;
  box-sizing: border-box;
  height: 98%;
  padding-bottom: 2%;
  min-width: 100%;
  &:hover {
    box-shadow: 2px 2px 10px -2px rgba(0, 0, 0, 0.71);
  }

  &.mobile {
    width: 100%;
    padding: 5px;
    box-shadow: none;
  }
`
export const Padding = styled.div`
  min-width: 20%;
  box-sizing: border-box;
  &.mobile {
    width: 100%;
    min-width: 0px;
  }
`

export const ItemMobileWrapper = styled.div`
  width: 50%;
  padding: 5px;
  box-sizing: border-box;
`

export const Wrapper = styled(WrapperMainComponent)`
  > * {
    max-width: 85em;
    margin: auto;
  }
  margin-top: 2em;
`

export const List = styled.div`
  width: auto;
  height: auto;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 1em;
  max-width: 100em;
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 2em;

  &.mobile {
    grid-template-columns: 1fr;
    width: 375px;
  }
`

export const Line = styled.hr``
export const CommonParaph = styled(CommonParagraph)`
  text-align: center;
  font-size: 23px;
`
export const Text = styled(CommonParagraph)``
export const InfiniteScrollDiv = styled.div`
  height: auto;
  overflow: auto;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  align-content: center;
`
export const InfiniteScrollStyled = styled(InfiniteScroll)`
  margin-bottom: 10em;
`

export const Loadingh4 = styled.h4`
  text-align: center;
`

export const LoadingEnd = styled.h4`
  text-align: center;
`
export const FooterWrapper = styled.div`
  display: flex;
  padding: 2em 0em;

  flex-direction: column;
  align-items: center;
`
export const PageNumberWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  > * {
    padding: 0.5em 1em;
    border: 1px solid #ababab;
    border-radius: 10px;
    text-align: center;
    ${({ theme }) => theme.typography.body}
    color: #2b3944;
  }
  > .currentPage {
    background-color: #c4ab88;
  }
  > .next {
    border: none;
    text-decoration: underline;
  }
`

export const PinInfoWrapper = styled(CommonParagraph)`
  display: flex;
  position: fixed;
  bottom: 10px;
  right: 20px;
  z-index: 1000;
  flex-direction: column;
  visibility: ${props => (props.isVisible ? "visible" : "hidden")};
  animation: ${props => (props.isVisible ? fadeIn : fadeOut)}
    ${props => (props.isVisible ? "0.5s" : "1.5s")} linear;
  transition: visibility 1s linear;

  background-color: ${({ theme }) => theme.palette.primary.contrastText};
  border-radius: 48px;
  padding: 0.5em 1em;
`
export const LoadMoreBTn = styled(Button)`
  max-width: 10em;
  margin-bottom: 2em;
  margin-left: auto;
  margin-right: auto;
`

const fadeIn = keyframes`
  from {
  /* transform: scale(.25); */
  opacity: 0;
  }

  to {
    /* transform: scale(1); */
    opacity: 1;
  }
`

const fadeOut = keyframes`
  from {
  /* transform: scale(1); */
  opacity: 1;
}

  to {
  /* transform: scale(.25); */
  opacity: 0;
}
`
