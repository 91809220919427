
import { gql, useQuery } from "@apollo/client";
import React, { } from "react";
import { ShortListProductView } from "./shortListProduct.view";
export const ShortListProductContainer = ({ collectionId, title }: { collectionId: string, title: string }) => {


  const { loading, error, data } = useQuery(GET_PRODUCTS_FROM_COLLECTION, { variables: { "id": collectionId } });

  const products = data?.collection.products.edges.map(({ node }) => node)

  return (
    <ShortListProductView {...{ products, loading, title }}></ShortListProductView>
  )
}


const GET_PRODUCTS_FROM_COLLECTION = gql`
  query collectionListProduct($id: ID) {
    collection(id: $id) {
      products(first :8){
        edges {
          node {
            id
            name
            slug
            thumbnail(size: 512) {
              url
              alt
            }
            attributes {
              attribute {
                slug
              }
              values {
                slug
              }
            }
            pricing {
              priceRange {
                start {
                  gross {
                    amount
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  
  `;